//import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Slider from "../../component/Slider/Slider";
import Marquee from "../../component/Marquee/Marquee";
import "./HomePage.css";
import { motion } from "framer-motion";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import HomeNoticeComponent from "../../component/HomeNoticeComponent/HomeNoticeComponent";
import crousal1 from "../../images/crousel2/c1.jpeg";
import crousal2 from "../../images/crousel2/c2.jpeg";
import crousal3 from "../../images/crousel2/c3.jpeg";
import crousal4 from "../../images/crousel2/c4.jpeg";
import crousal5 from "../../images/crousel2/c5.jpeg";
import crousal6 from "../../images/crousel2/c6.jpg";

import { base_URL } from "../../Middleware/config";
import { NewsEvents } from "./Sections/NewsEvents";
import Publications from "./Sections/Publications";
import CampusMessage from "./Sections/CampusMessage/CampusMessage";
import { useFetch } from "../../utils";
// import FlashNoticeModal from "../../component/FlashNoticeModal/FlashNoticeModal";

const HomePage = (props) => {

  const { data: notice, loading, error } = useFetch(`${base_URL}/notice/search?search=&limit=7&offset=0`);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Slider />
      {loading && <h2>Loading...</h2>}
      {notice && <Marquee data={notice} />}
      {error && <h2>Error!!</h2>}
      <section
        className="campus-message-wrapper">
        <CarouselMessage />
        <CampusMessage />
      </section>
      <header className="text-center m-2">
        <h1>Recent Events</h1>
      </header>
      <motion.section
        className="news-wrapper">

        <div className="news-content">
          <div >
            <h3 className="text-center mb-1">Gallery</h3>
            <NewsEvents />
          </div>
        </div>

        <div>
          <h3 className="text-center mb-1"> Publications</h3>
          <div className="pub-container">
            <article>
              <Publications />
            </article>
          </div>
        </div>
        <section className="hm-notification-section">
          <h3 className="text-center mb-1">Notices</h3>
          <article>
            {notice && <HomeNoticeComponent notice={notice} />}
          </article>
        </section>
      </motion.section>
    </motion.div>
  );
};

export default HomePage;

function CarouselMessage() {

  return (
    <div className="carou-message">

      <CarouselSlider />
      <p className="carou-text-content">
        Thakur Ram Multiple Campus was established six decades ago with a view of imparting higher education and to cater the needs of people in this region. It has a glorious history as it was started even before the establishment of Tribhuvan University. Established in 2nd Shrawan, 2009 B.S., Thakur Ram Multiple Campus Birgunj has been one of the pioneering academic institutions, not only in the Central Terai, but also in the entire country. Immediately after the establishment of democracy (2007 B.S.), the campus came into existence with an aim of imparting higher education to the people in the region. It is the first campus founded outside the Kathmandu valley and the third in the whole country. During its inception, it was run under the affiliation to Patna University, India.The campus celebrated its Golden Jubilee in 2059 B.S which proved to be a stimulating factor for its growth. It was a matter of pride and satisfaction for the campus that it has been able to contribute considerably in the production of statesmen, literary figures, scholars, artists, social workers, industrialists, doctors and technical manpower within the period. The campus has since then steadily moved forward both in quantity and quality.
      </p>
    </div>
  );
}

const CarouselSlider = () => {
  const dispImages = [crousal1, crousal2, crousal3, crousal4, crousal5, crousal6];
  const [currentImg, setCurrentImg] = useState(0);
  const imgRef = useRef(null);
  const indicatorRef = useRef(null);
  const currentImgRef = useRef()

  currentImgRef.current = currentImg

  useEffect(() => {
    let dots = Array.from(indicatorRef.current.children);
    dots.forEach((d, i) => {
      i === currentImg ? d.classList.toggle('active') : d.classList.remove('active')
    })

  }, [currentImg])

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentImgRef.current >= 0) {
        setCurrentImg(prev => prev + 1)
        if (currentImgRef.current === dispImages.length - 1) {
          setCurrentImg(0)
        }
      }
    }, 4000)
    return () => clearInterval(interval)
  }, [dispImages.length])

  const prevHandler = (e) => {
    currentImg === 0 ? setCurrentImg(dispImages.length - 1) : setCurrentImg(currentImg - 1)
  }
  const nextHandler = (e) => {
    currentImg === dispImages.length - 1 ? setCurrentImg(0) : setCurrentImg(currentImg + 1)
  }
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (<div className="ca-slider">
    <img ref={imgRef} src={dispImages[currentImg]} className="ca-img" alt="" onContextMenu={handleContextMenu} />
    <FiChevronLeft className="ca-prev" onClick={prevHandler} />
    <FiChevronRight className="ca-next" onClick={nextHandler} />
    <div ref={indicatorRef} class="indicator">
      {dispImages.map((img, index) => {
        return index === 0 ? <span key={index} class="d active"></span> :
          <span key={index} class="d"></span>
      })}
    </div>
  </div>)
}
